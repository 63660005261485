.low_dir {
    &.aboutus {
        .ld_title {
            img {
                max-width: 400px;

                @include media(pc) {
                    width: 467px;
                }
            }

            @media screen and (max-width: 530px) {
                span.sub_title {
                    display: block;
                    margin-left: 0;
                }
            }
        }
    }
}

div.aboutus_content {
    @include media(pc) {
        margin-bottom: 60px;
    }

    dl {
        border-bottom: $border-gray;
        display: flex;

        &:nth-child(1) {
            border-top: $border-gray;
        }

        &:nth-child(2n+1) {
            background: $color-bg;
        }

        @include media(pc) {
            height: 57px;
        }

        dt {
            display: flex;
            align-items: center;
            padding-left: 4%;
            width: 19%;
        }

        dd {
            display: flex;
            align-items: center;
        }
    }
}

.map_area {
    @include media(pc) {
        cursor: pointer;
        max-width: 788px;
        margin: 0 auto;
    }
}