/* topのworks */

section.top_works {
    background: $color-bg;

    div.wrap {
        h2.top_title {
            @include media(pc) {
                margin-bottom: 40px;
            }

            img {
                width: 116px;
            }
        }
    }
}

div.works_textarea {
    text-align: center;
    margin-bottom: 22px;

    @include media(pc) {
        margin-bottom: 60px;

        p {
            font-size: 20px;
        }
    }
}

div.works_linkarea.box_flex {
    max-width: 320px;
    margin: 0 auto;

    @include media(pc) {
        max-width: inherit;
        margin-left: -7px;
        flex-wrap: nowrap;
    }

    a {
        margin-bottom: 6px;

        @include media(pc) {
            margin-bottom: 0;
            margin-left: 7px;

            &:hover {
                padding: 0;
                margin-left: 5px;
            }
        }

        &:nth-last-child(1) {
            margin-bottom: 0;
        }
    }
}