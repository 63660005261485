// @font-face {
//   font-family: 'icomoon';
//   src:  url('../fonts/icomoon.eot?ijrj6d');
//   src:  url('../fonts/icomoon.eot?ijrj6d#iefix') format('embedded-opentype'),
//     url('../fonts/icomoon.ttf?ijrj6d') format('truetype'),
//     url('../fonts/icomoon.woff?ijrj6d') format('woff'),
//     url('../fonts/icomoon.svg?ijrj6d#icomoon') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }

// [class^="icon-"], [class*=" icon-"] {
//   /* use !important to prevent issues with browser extensions that change fonts */
//   font-family: 'icomoon' !important;
//   speak: none;
//   font-style: normal;
//   font-weight: normal;
//   font-variant: normal;
//   text-transform: none;
//   line-height: 1;

//   /* Better Font Rendering =========== */
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// .icon-facebook:before {
//   content: "\ea90";
// }


/* SNSボタン */
.share {
    margin-top : 40px;
  }
.share ul {
    margin : 0;
    padding : 0;
    list-style : none;
  }
.share li a {
    display : block;
    // padding : 10px;
    color : #fff;
    font-size : 14px;
    text-decoration : none;
    text-align : center;}
.share li a:hover {
    opacity :0.8;
    color : #fff;}
.share li a:visited{ color: #fff;}
// .share ul:after {content : "";
//     display : block;
//     clear : both;}
.facebook a{
    margin-left: auto;
    margin-right: auto;
    width: 35px;
    border-radius: 50%;
}
// .tweet a{background-color : #55acee;}
// .facebook a{background-color : #315096;}
// .googleplus a{background-color : #dd4b39;}
// .hatena a{background-color : #008fde;}
// .line a{background-color: #00c300;}
// .pocket a{background-color :#f03e51;}
// .rss a{background-color: #ff8c00;}
// .feedly a{background-color: #6cc655;}

.share li {
//     float : left;
//     width : 25%;
    width : 100%;
    margin : 0;
    margin-top:15px;
  }
div.blog_subcontents .share li {
    padding-left: 0;
  border-bottom: none;}
@media screen and (max-width:599px){
.share li {
  float : left;
  width : 100%;
  margin:0 2% 3% 0;}
.share li a {border-radius:6px;}
}