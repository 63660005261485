/*! =======================================
このCSSファイルはSassから生成されていますので、
編集しないようにご注意ください。
========================================= */

/*!
メインの設定(全体)
------------------------------- */
*,
*::before,
*::after {
    box-sizing: border-box; // 1
}

* {
    margin: 0;
    padding: 0;
}

body {
    font-size: $fontsize-base;
    width: 100%;
    font-family: $font-base;
    font-weight: normal;
    line-height: $line_height;
    color: $color-font;
}

li {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: $color-font;
    display: block;
}

a:hover {
    opacity: 0.8;
}

img {
    max-width: 100%;
    width: 100%;
    vertical-align: bottom;
}

p {
    line-height: $line_height;
}


.sp-none {
    display: none;

    @include media(pc) {
        display: block;
    }
}

@include media(pc) {
    .pc-none {
        display: none;
    }
}

.wrap {
    width: 92%;
    margin: 0 auto;

    @include media(pc) {
        max-width: $width-base;
        width: 100%;
    }
}

.box_flex {
    @include media(pc) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.align_center {
    text-align: center;

    @include media(pc) {
        text-align: left;
    }
}

.none {
    display: none;
}

.post-categories li {
    a {
        color: #fff;
    }
}

.cat-1 {
    background: $color-cat1;
}

.cat-2 {
    background: $color-cat2;
}

.cat-3 {
    background: $color-cat3;
}

.cat-4 {
    background: $color-cat4;
}

.cat-5 {
    background: $color-cat5;
}

.yumincho {
    font-family: "游明朝体",
        "Yu Mincho",
        YuMincho,
        "ヒラギノ明朝 Pro",
        "Hiragino Mincho Pro",
        "MS P明朝",
        "MS PMincho",
        serif;
}

/* TOPページ */

.btn_green {
    @include mannaka;
    background: $color-btn;
    position: relative;
    padding: 18px 0;
    max-width: 320px;
    margin: 0 auto;

    @include media(pc) {
        padding: 13px 0;
        max-width: 185px;

    }

    .btn_text {
        color: #fff;
        font-size: 16px;

        @include media(pc) {
            font-size: 14px;

        }

        &::after {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            display: block;
            content: '>';
        }

    }
}

.top_title {
    text-align: center;
    margin-bottom: 20px;

    @include media(pc) {
        margin-bottom: 40px;
    }

    .sub_title {
        font-size: 12px;
        line-height: 1;
        display: inline-block;

        @include media(pc) {
            font-size: inherit;
        }

        &::before {
            @include gizi();
            margin: 6px auto;
            width: 28px;
            height: 1px;
            background: $color-font;

            @include media(pc) {
                margin: 13px auto 16px;
            }
        }
    }
}

/* Lower Dir */
div.ld_main_container {
    padding-top: 25px +$sph_height;
    padding-bottom: 50px;

    @include media(pc) {
        padding-top: 120px+$pch_height;
        padding-bottom: 100px;
    }
}

.low_dir {
    .ld_title {
        margin: 0 20px 25px 20px;

        img {
            margin-bottom: 5px;

            @include media(pc) {}
        }

        span.sub_title {
            margin-left: 30px;

            @include media(pc) {
                font-size: 20px;
            }
        }

        @include media(pc) {
            margin-bottom: 55px;
        }
    }

    .ldimg_area {
        margin-bottom: 20px;

        @include media(pc) {
            margin-bottom: 100px;
        }
    }
}