/* topのmessage */
section.top_message {
    background: #fff;

    div.wrap {
        h2.top_title {
            img {
                width: 112px;
            }
        }
    }
}

div.box_position {
    @include media(pc) {
        position: relative;
    }

    div.message_imgarea {
        @include media(pc) {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 260px;
        }
    }

    div.message_textarea {
        margin-bottom: 22px;

        @include media(pc) {
            margin-bottom: 24px;
            width: calc(100% - 260px);
            letter-spacing: -1px;

            p {
                line-height: 1.7;

                &+p {
                    margin-top: 1em;
                }
            }
        }

        p {
            line-height: 1.9;
        }

        .zayuu {
            font-size: 20px;
        }
    }
}