/* footer */
footer {
    background: $color-main;
    padding-top: 12px;
    padding-bottom: 10px;

    @include media(pc) {
        padding-top: 20px;
        padding-bottom: 14px;
    }

    div.wrap {
        margin: 0 auto;
        font-size: 12px;
        color: #ffffff;
        max-width: 240px;

        @include media(pc) {
            max-width: $width-base;
            text-align: left;
        }

        div.contactinfo {
            p {
                a {
                    color: inherit;
                    display: inline;
                }
            }
        }

        small {
            font-size: 10px;
        }
    }

    div.box_flex {
        @include media(pc) {
            width: 356px;
        }

        div {
            padding-bottom: 10px;

            img {
                width: 100px;
            }
        }

    }
}