/* header */
header {
    width: 100%;
    position: fixed;
    z-index: 9999;
    background: #fff;

    @include media(pc) {
        border-bottom: $border-gray;
    }
}

.header {
    height: $sph_height;
    padding: 9px 9px 0;

    @include media(pc) {
        height: $pch_height;
        padding-left: 24px;
    }

    h1 {
        width: 74px;

        @include media(pc) {
            width: 100px;
        }
    }
}

/* contact */
.btn_contact {
    position: absolute;
    top: 0;
    right: 65px;

    @include media(pc) {
        right: 0;
    }

    a {
        img {
            width: 65px;
            height: $sph_height;

            @include media(pc) {
                width: 140px;
                height: $pch_height;
            }
        }
    }
}

/* nav */
.nav {
    top: $sph_height;
    width: 100%;
    background: $color-spnav;
    padding-left: 55px;
    padding-right: 55px;
    display: none;
    position: absolute;
    left: 0;

    @include media(pc) {
        display: block;
        right: 140px;
        left: inherit;
        top: 0;
        background: inherit;
        padding: 0;
        width: inherit;
    }

    ul {
        margin: 0 auto;
        width: 90%;

        @include media(pc) {
            width: inherit;
            margin: 0;
        }

        li {
            border-bottom: 1px solid #fff;
            padding-top: 24px;
            padding-bottom: 20px;
            margin: 0 auto;
            text-align: left;

            &:last-child {
                border: none;

                @include media(pc) {
                    border-left: 0.75px solid #e5e5e5;
                }
            }

            @include media(pc) {
                @include mannaka();
                width: 102px;
                margin: 0;
                border-left: 0.75px solid #e5e5e5;
                height: $pch_height;

                &.current {
                    background: #8cb362;
                }
            }


            a {
                color: #fff;
                display: block;

                @include media(pc) {
                    text-align: center;
                    color: inherit;
                }

                img {
                    vertical-align: text-bottom;
                }

                span {
                    margin-left: 1em;

                    @include media(pc) {
                        margin: 0;
                        display: block;
                        font-size: 12px;
                    }
                }


            }
        }
    }
}

li.nav_works {

    a {

        img {
            width: 116px;

            @include media(pc) {
                width: 74px;
            }
        }
    }
}

li.nav_aboutus {
    a {
        img {
            width: 132px;

            @include media(pc) {
                width: 84px;
            }
        }
    }
}

li.nav_link {
    a {
        img {
            width: 57px;

            @include media(pc) {
                width: 37px;
            }
        }
    }
}

li.nav_blog {
    a {
        img {
            width: 62px;

            @include media(pc) {
                width: 40px;
            }
        }
    }
}

/*開閉ボタンopen時*/
header.open {
    background: $color-spnav;
    color: #fff;

    .header {
        position: relative;

        &::after {
            position: absolute;
            bottom: 0;
            left: 1%;
            @include gizi;
            z-index: 9999;
            width: 98%;
            height: 1px;
            background: #fff;
        }
    }

    #nav_toggle span {
        background: #fff;

        &:nth-child(1) {
            top: 12px;
            -webkit-transform: rotate(135deg);
            -moz-transform: rotate(135deg);
            transform: rotate(135deg);
        }

        &:nth-child(2) {
            width: 0;
            left: 50%;
        }

        &:nth-child(3) {
            top: 12px;
            -webkit-transform: rotate(-135deg);
            -moz-transform: rotate(-135deg);
            transform: rotate(-135deg);
        }

        &.menu_text {
            color: inherit;
            background: inherit;
        }
    }
}

/* nav_toggle */
#nav_toggle {
    display: block;
    width: 65px;
    height: $sph_height;
    padding: 15px 15px 0 15px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    text-align: center;

    @include media(pc) {
        display: none;
    }

    div {
        position: relative;
    }

    span {
        display: block;
        height: 3px;
        background: $color-spnav;
        position: absolute;
        width: 100%;
        left: 0;
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;

        &.menu_text {
            font-size: 9px;
            height: inherit;
            color: $color-spnav;
            background: inherit;
        }

        &:nth-child(1) {
            top: 0px;
        }

        &:nth-child(2) {
            top: 10px;
        }

        &:nth-child(3) {
            top: 20px;
        }

        &:nth-child(4) {
            top: 30px;
        }
    }
}