/* topのblog */
section.top_blog {
    background: $color-bg;

    div.wrap {
        h2.top_title {
            img {
                width: 62px;
            }
        }
    }
}

div.blog_listarea.box_flex {
    margin-bottom: 22px;
    justify-content: flex-start;

    @include media(pc) {
        margin-bottom: 40px;
        flex-wrap: nowrap;
        margin-left: -19px;
    }

    .blog_list {
        background: #fff;
        display: flex;
        padding: 8px 10px 7px 0;
        margin-bottom: 15px;
        position: relative;

        @include media(pc) {
            width: calc(33.3% - 30px);
            padding: 0;
            display: block;
            margin-left: 30px;
        }

        div.img_center {
            @include mannaka;
            width: 35%;
            padding-left: 7px;
            padding-right: 8px;

            @include media(pc) {
                width: 100%;
                padding: 0;
                height: 200px;
                overflow: hidden;
            }

            img {
                height: auto;
            }
        }
    }
}

div.blog_content {
    width: 65%;

    @include media(pc) {
        width: 100%;
        position: relative;
        padding: 60px 25px 50px;
    }

    div.bloglist_textarea {
        p.blog_time {
            font-size: 10px;

            @include media(pc) {
                font-size: inherit;
            }
        }

        p.h_1em.blog_title {
            font-weight: bold;
            overflow: hidden;

            @include media(pc) {
                font-size: 16px;
            }
        }

        p.h_3em.blog_excerpt {
            font-size: 13px;

            @include media(pc) {
                font-size: inherit;
                height: 92px;
                overflow: hidden;
            }

            .continue {
                display: none;
            }
        }

        @include media(pc) {
            padding-bottom: 30px;
            border-bottom: 0.5px solid #e0e1dc;
        }
    }

    &>p {
        position: absolute;
        bottom: 7px;
        right: 10px;
        text-align: right;

        @include media(pc) {
            padding-top: 7px;
            bottom: 20px;
            right: 25px;
        }

        img {
            width: 84px;

            @include media(pc) {
                width: 90px;
            }
        }
    }

    .post-categories li {
        top: 3px;
        left: -6px;
        position: absolute;
        font-size: 10px;
        color: #fff;
        padding: 0 10px;

        @include media(pc) {
            top: 20px;
            left: -11px;
            font-size: inherit;
            padding: 2px 36px;
        }

        &::after {
            @include gizi;
            position: absolute;
            left: 0;
            bottom: -5px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 6px 5px 0;

            @include media(pc) {
                border-width: 0 11px 9px 0;
                bottom: -9px;
            }
        }

        &.cat-1 {
            &::after {
                border-color: transparent #343504 transparent transparent;
            }
        }

        &.cat-2 {
            &::after {
                border-color: transparent #336633 transparent transparent;
            }
        }

        &.cat-3 {
            &::after {
                border-color: transparent #cc6600 transparent transparent;
            }
        }

        &.cat-4 {
            &::after {
                border-color: transparent #c03740 transparent transparent;
            }
        }

        &.cat-5 {
            &::after {
                border-color: transparent #535FA3 transparent transparent;
            }
        }
    }
}