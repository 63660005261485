.low_dir {
    &.blog {
        .ld_title {
            img {
                max-width: 91px;

                @include media(pc) {
                    width: 106px;
                }
            }

            .archive-title {
                margin-left: 30px;
            }
        }
    }
}

div.container.box_flex {

    div.bloglist_contents {
        @include media(pc) {
            width: calc(100% - 266px);
            max-width: 693px;
        }
    }
}



div.bloglist_content {
    img {
        width: inherit;
        height: inherit;
    }

    &+.bloglist_content {
        margin-top: 50px;

        @include media(pc) {
            margin-top: 80px;
        }
    }

    .blog_contenttitle {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: $border-green;

        @include media(pc) {
            padding-bottom: 10px;
            margin-bottom: 56px;
        }

        h3 {
            font-size: 18px;
            font-weight: 600;
            padding-bottom: 10px;

            @include media(pc) {
                font-size: 20px;
                padding-bottom: 5px;
            }

        }

        p {
            @include media(pc) {
                display: inline;
                font-size: 15px;
                margin-right: 18px;
            }

        }

        .post-categories {
            display: inline-block;

            li {
                padding-left: 34px;
                padding-right: 34px;
                display: inline-block;

            }
        }
    }
}

div.blog_subcontents {
    @include media(pc) {
        width: 200px;
    }

    p.blog_subtitle {
        border-bottom: $border-green;

        @include media(pc) {
            padding-left: 12px;
            padding-bottom: 19px;
        }
    }

    ul {
        li {
            @include media(pc) {
                padding-left: 12px;
                border-bottom: 1px solid #dddddd;
            }
        }
    }


}

div.blog_subcontent1 {
    p.blog_subtitle {
        img {
            width: 124px;
        }
    }

    li {
        position: relative;
        padding-top: 37px;

        @include media(pc) {
            padding-bottom: 13px;
        }

        .post-date {
            font-size: 8px;
            position: absolute;
            top: 15px;
            left: 0;

            @include media(pc) {
                left: 12px;
            }
        }
    }
}

div.blog_subcontent2 {
    @include media(pc) {
        margin-top: 60px;

    }

    p.blog_subtitle {
        img {
            width: 95px;
        }
    }

    li {
        @include media(pc) {
            padding-top: 18px;
            padding-bottom: 16px;
        }

        a {
            span {}

            p {}
        }
    }
}


/* wordpress post-navigation */
.post-navigation {
    margin-top: 3em;
}

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
}

.nav-links {
    border-top: 1px solid #ccc;
    padding: .5em 0;
}

.nav-links:after {
    content: "";
    display: block;
    clear: both;
}

.nav-previous {
    float: left;
}

.nav-next {
    float: right;
    text-align: right;
}

.nav-previous,
.nav-next {
    width: 40%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* wordpress pagination */
.pagination h2.screen-reader-text {
    display: none;
}

.pagination {
    a {
        padding: 1px 8px;
        display: inline-block;
        border: solid 1px #cccccc;
        color: #666666;
        background-color: #ffffff;
        font-size: 1em;
        text-decoration: none;

        &:hover {
            background-color: #e8e8e8;
        }
    }

    span {
        padding: 1px 8px;
        display: inline-block;

        &.current {
            color: #ffffff;
            font-size: 1em;
            background-color: #99cc66;
            border: 1px solid #99cc66;
        }
    }

    position: relative !important;
    overflow: hidden;
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
    clear: both;
    background-color: transparent;

    h2.screen-reader-text {
        display: none;
    }
}

/* 768px以上 */
@media (min-width: 768px) {
    .pagination {
        a {
            padding: 1px 10px;
            display: inline-block;
            border: solid 1px #cccccc;
            color: #666666;
            background-color: #ffffff;
            font-size: 1.19em;
            text-decoration: none;
        }

        span {
            &.current {
                color: #ffffff;
                font-size: 1.19em;
                background-color: #99cc66;
                border: 1px solid #99cc66;
            }

            padding: 1px 10px;
            display: inline-block;
        }
    }
}

.continue {
    display: inline-block;
    margin-left: 1em;
}

.side_ttl {
    display: none;
}