/* link */
.low_dir {
    &.link {
        .ld_title {
            img {
                width: 92px;

                @include media(pc) {
                    width: 103px;
                }
            }
        }
        .link_list {
            border-bottom: 1px solid #c3c3c3;
            padding: 1em;
            &:nth-of-type(1) {
                border-top: 1px solid #c3c3c3;
            }
            .link_url {
                padding-top: 0.6em;
            }
            @include media(pc) {
                padding: 20px 0;
                display: flex;
                .link_name {
                    width: 31%;
                    padding: 0 0 0 40px;
                }
                .link_url {
                    padding: 0 40px 0 40px;
                }
            }
        }
    }
}