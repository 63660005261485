.works {
    .ld_title {
        text-align: center;

        img {
            width: 171px;
            margin-left: auto;
            margin-right: auto;

            @include media(pc) {
                width: 200px;
            }
        }
    }

    .ld_title2 {
        @include media(pc) {
            text-align: left;
            margin: 0 0 50px;
        }

        img {
            width: 240px;

            @include media(pc) {
                width: 378px;
            }
        }

        span.sub_title {
            @media screen and (max-width: 402px) {
                margin-left: 0;
                display: block;
            }
        }
    }

    .ld_title3 {
        @include media(pc) {
            margin: 0 0 50px;
            text-align: left;
        }

        img {
            @include media(pc) {
                width: 267px;
            }
        }
    }

    .works_text2 {
        margin-bottom: 37px;

        @include media(pc) {
            margin-bottom: 75px;
        }
    }
}

div.works_topvi {
    text-align: center;
    margin-bottom: 80px;

    @include media(pc) {
        margin-bottom: 135px;
    }

    .works_text {
        margin: 0 auto 30px;
        width: 92%;
        font-size: 16px;
        line-height: 2.3;
        text-align: left;

        @include media(pc) {
            width: inherit;
            text-align: center;
            font-size: 2.5vw;
            margin-bottom: 135px;
        }

        @media screen and (min-width: 1180px) {
            font-size: 30px;
        }
    }

    img {
        @include media(pc) {
            &.works_catch {
                max-width: 499px;
                margin-bottom: 84px;
            }

            /*
            &.works_text {
                max-width: 788px;
                margin-bottom: 135px;
            }
            */
        }
    }

    div.worksimg_contents.box_flex {
        @include media(pc) {
            margin-bottom: 35px;
        }
    }


}

.works_text1 {
    text-align: left;
    font-size: 16px;
    margin: 0 auto 80px;

    ul {
        width: 100%;
    }

    li {
        width: 100%;
        margin-top: 0.5em;

        &::before {
            content: '●';
            display: inline-block;
            margin-right: 1em;
        }
    }

    @include media(pc) {
        line-height: 1.75;
        max-width: 100%;
    }
}

div.works_content {

    div.rinen_content {
        &:nth-last-child(1) {
            margin-bottom: 0;
        }

        @include media(pc) {
            margin-bottom: 50px;

        }

        h3 {
            @include media(pc) {
                margin-bottom: 30px;
            }
        }
    }

    div.rinen_content1 {
        h3 {
            img {
                @include media(pc) {
                    width: 298px;
                }
            }
        }
    }

    div.rinen_content2 {
        h3 {
            img {
                @include media(pc) {
                    width: 417px;
                }
            }
        }
    }

    div.rinen_content3 {
        h3 {
            img {
                @include media(pc) {
                    width: 478px;
                }
            }
        }
    }
}