.bl_modalBlock {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 100;
}

.js_openModal .bl_modalBlock {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.bl_modalBlock_wrapper {
    width: 90%;
    max-width: 480px;
    height: auto;
    position: relative;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center center;

    &:before {
        content: "";
        display: block;
        padding-top: 100%;
    }

    @include media(pc) {
        width: 80vh;
        max-width: inherit;

        &.map_area_modal {
            width: 70%;
        }
    }
}


.bl_modalBlock_closeButton {
    position: absolute;
    right: 0;
    top: -10px;
    width: 20px;
    height: 20px;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    cursor: pointer;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 149%;
        height: 2px;
        background-color: #ccc;
        -webkit-transform-origin: top left;
        -ms-transform-origin: top left;
        transform-origin: top left;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 149%;
        height: 2px;
        background-color: #ccc;
        -webkit-transform-origin: top right;
        -ms-transform-origin: top right;
        transform-origin: top right;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    @include media(pc) {
        &.map_area_modal {
            top: 16%;
        }
    }
}

/*スクロール禁止*/

.js_fixed {
    position: fixed;
    width: 100%;
    height: 100%;
}