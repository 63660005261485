.low_dir {
    &.contact {
        .ld_title {

            img {
                width: 171px;

                @include media(pc) {
                    width: 200px;
                }
            }
        }
    }
}

div.container {
    .contact_content {
        .form_list {
            @include media(pc) {}

            &+.form_list {
                margin-top: 16px;
            }

            &:nth-last-of-type(1) {
                margin-bottom: 30px;

                @include media(pc) {
                    margin-bottom: 55px;
                }
            }


            dt {
                padding-top: 9px;
                line-height: 1.4;
                font-size: $fontsize-base;

                @include media(pc) {
                    text-align: right;
                }
            }

            dd {
                margin-top: 0.5em;
                padding-top: 9px;
                line-height: 1.4;

                @include media(pc) {
                    margin-top: 0;
                    width: calc(100% - 183px);
                }
            }

            .required {
                color: #ff0000;
                margin-right: 0.5em;
            }

            @include media(pc) {
                dt {
                    width: 183px;
                    padding-right: 18px;
                }
            }


        }
    }
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
    height: 100%;
    box-sizing: border-box;
    border: 0.5px solid #333333;
    width: 100%;
    font-size: 16px;
    padding: 0.5em;

    @include media(pc) {
        padding: 1em;
    }
}

textarea {
    height: 147px;
}

.btn_confirm {
    display: block;
    text-align: center;
    color: #fff;
    background: $color-btn;
    max-width: 390px;
    width: 100%;
    margin: 0 auto;
    line-height: 50px;
    font-size: 15px;

    @include media(pc) {
        font-size: 18px;
        line-height: 70px;
    }

    &::after {
        content: '\003e';
        display: inline-block;
        margin-left: 2em;
    }
}