@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*! =======================================
このCSSファイルはSassから生成されていますので、
編集しないようにご注意ください。
========================================= */
/*!
メインの設定(全体)
------------------------------- */
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-size: 14px;
  width: 100%;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  font-weight: normal;
  line-height: 1.7;
  color: #333333;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #333333;
  display: block;
}

a:hover {
  opacity: 0.8;
}

img {
  max-width: 100%;
  width: 100%;
  vertical-align: bottom;
}

p {
  line-height: 1.7;
}

.sp-none {
  display: none;
}

@media screen and (min-width: 768px) {
  .sp-none {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .pc-none {
    display: none;
  }
}

.wrap {
  width: 92%;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .wrap {
    max-width: 960px;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .box_flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.align_center {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .align_center {
    text-align: left;
  }
}

.none {
  display: none;
}

.post-categories li a {
  color: #fff;
}

.cat-1 {
  background: #4A4A4A;
}

.cat-2 {
  background: #8bb543;
}

.cat-3 {
  background: #d7972d;
}

.cat-4 {
  background: #C05E40;
}

.cat-5 {
  background: #539AA3;
}

.yumincho {
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
}

/* TOPページ */
.btn_green {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #006633;
  position: relative;
  padding: 18px 0;
  max-width: 320px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .btn_green {
    padding: 13px 0;
    max-width: 185px;
  }
}

.btn_green .btn_text {
  color: #fff;
  font-size: 16px;
}

@media screen and (min-width: 768px) {
  .btn_green .btn_text {
    font-size: 14px;
  }
}

.btn_green .btn_text::after {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  display: block;
  content: '>';
}

.top_title {
  text-align: center;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .top_title {
    margin-bottom: 40px;
  }
}

.top_title .sub_title {
  font-size: 12px;
  line-height: 1;
  display: inline-block;
}

@media screen and (min-width: 768px) {
  .top_title .sub_title {
    font-size: inherit;
  }
}

.top_title .sub_title::before {
  content: '';
  display: block;
  margin: 6px auto;
  width: 28px;
  height: 1px;
  background: #333333;
}

@media screen and (min-width: 768px) {
  .top_title .sub_title::before {
    margin: 13px auto 16px;
  }
}

/* Lower Dir */
div.ld_main_container {
  padding-top: 87px;
  padding-bottom: 50px;
}

@media screen and (min-width: 768px) {
  div.ld_main_container {
    padding-top: 200px;
    padding-bottom: 100px;
  }
}

.low_dir .ld_title {
  margin: 0 20px 25px 20px;
}

.low_dir .ld_title img {
  margin-bottom: 5px;
}

.low_dir .ld_title span.sub_title {
  margin-left: 30px;
}

@media screen and (min-width: 768px) {
  .low_dir .ld_title span.sub_title {
    font-size: 20px;
  }
}

@media screen and (min-width: 768px) {
  .low_dir .ld_title {
    margin-bottom: 55px;
  }
}

.low_dir .ldimg_area {
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .low_dir .ldimg_area {
    margin-bottom: 100px;
  }
}

/* SNSボタン */
.share {
  margin-top: 40px;
}

.share ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.share li a {
  display: block;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  text-align: center;
}

.share li a:hover {
  opacity: 0.8;
  color: #fff;
}

.share li a:visited {
  color: #fff;
}

.facebook a {
  margin-left: auto;
  margin-right: auto;
  width: 35px;
  border-radius: 50%;
}

.share li {
  width: 100%;
  margin: 0;
  margin-top: 15px;
}

div.blog_subcontents .share li {
  padding-left: 0;
  border-bottom: none;
}

@media screen and (max-width: 599px) {
  .share li {
    float: left;
    width: 100%;
    margin: 0 2% 3% 0;
  }
  .share li a {
    border-radius: 6px;
  }
}

/* header */
header {
  width: 100%;
  position: fixed;
  z-index: 9999;
  background: #fff;
}

@media screen and (min-width: 768px) {
  header {
    border-bottom: 0.5px solid #cccccc;
  }
}

.header {
  height: 62px;
  padding: 9px 9px 0;
}

@media screen and (min-width: 768px) {
  .header {
    height: 80px;
    padding-left: 24px;
  }
}

.header h1 {
  width: 74px;
}

@media screen and (min-width: 768px) {
  .header h1 {
    width: 100px;
  }
}

/* contact */
.btn_contact {
  position: absolute;
  top: 0;
  right: 65px;
}

@media screen and (min-width: 768px) {
  .btn_contact {
    right: 0;
  }
}

.btn_contact a img {
  width: 65px;
  height: 62px;
}

@media screen and (min-width: 768px) {
  .btn_contact a img {
    width: 140px;
    height: 80px;
  }
}

/* nav */
.nav {
  top: 62px;
  width: 100%;
  background: #00a746;
  padding-left: 55px;
  padding-right: 55px;
  display: none;
  position: absolute;
  left: 0;
}

@media screen and (min-width: 768px) {
  .nav {
    display: block;
    right: 140px;
    left: inherit;
    top: 0;
    background: inherit;
    padding: 0;
    width: inherit;
  }
}

.nav ul {
  margin: 0 auto;
  width: 90%;
}

@media screen and (min-width: 768px) {
  .nav ul {
    width: inherit;
    margin: 0;
  }
}

.nav ul li {
  border-bottom: 1px solid #fff;
  padding-top: 24px;
  padding-bottom: 20px;
  margin: 0 auto;
  text-align: left;
}

.nav ul li:last-child {
  border: none;
}

@media screen and (min-width: 768px) {
  .nav ul li:last-child {
    border-left: 0.75px solid #e5e5e5;
  }
}

@media screen and (min-width: 768px) {
  .nav ul li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 102px;
    margin: 0;
    border-left: 0.75px solid #e5e5e5;
    height: 80px;
  }
  .nav ul li.current {
    background: #8cb362;
  }
}

.nav ul li a {
  color: #fff;
  display: block;
}

@media screen and (min-width: 768px) {
  .nav ul li a {
    text-align: center;
    color: inherit;
  }
}

.nav ul li a img {
  vertical-align: text-bottom;
}

.nav ul li a span {
  margin-left: 1em;
}

@media screen and (min-width: 768px) {
  .nav ul li a span {
    margin: 0;
    display: block;
    font-size: 12px;
  }
}

li.nav_works a img {
  width: 116px;
}

@media screen and (min-width: 768px) {
  li.nav_works a img {
    width: 74px;
  }
}

li.nav_aboutus a img {
  width: 132px;
}

@media screen and (min-width: 768px) {
  li.nav_aboutus a img {
    width: 84px;
  }
}

li.nav_link a img {
  width: 57px;
}

@media screen and (min-width: 768px) {
  li.nav_link a img {
    width: 37px;
  }
}

li.nav_blog a img {
  width: 62px;
}

@media screen and (min-width: 768px) {
  li.nav_blog a img {
    width: 40px;
  }
}

/*開閉ボタンopen時*/
header.open {
  background: #00a746;
  color: #fff;
}

header.open .header {
  position: relative;
}

header.open .header::after {
  position: absolute;
  bottom: 0;
  left: 1%;
  content: '';
  display: block;
  z-index: 9999;
  width: 98%;
  height: 1px;
  background: #fff;
}

header.open #nav_toggle span {
  background: #fff;
}

header.open #nav_toggle span:nth-child(1) {
  top: 12px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  transform: rotate(135deg);
}

header.open #nav_toggle span:nth-child(2) {
  width: 0;
  left: 50%;
}

header.open #nav_toggle span:nth-child(3) {
  top: 12px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

header.open #nav_toggle span.menu_text {
  color: inherit;
  background: inherit;
}

/* nav_toggle */
#nav_toggle {
  display: block;
  width: 65px;
  height: 62px;
  padding: 15px 15px 0 15px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  text-align: center;
}

@media screen and (min-width: 768px) {
  #nav_toggle {
    display: none;
  }
}

#nav_toggle div {
  position: relative;
}

#nav_toggle span {
  display: block;
  height: 3px;
  background: #00a746;
  position: absolute;
  width: 100%;
  left: 0;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

#nav_toggle span.menu_text {
  font-size: 9px;
  height: inherit;
  color: #00a746;
  background: inherit;
}

#nav_toggle span:nth-child(1) {
  top: 0px;
}

#nav_toggle span:nth-child(2) {
  top: 10px;
}

#nav_toggle span:nth-child(3) {
  top: 20px;
}

#nav_toggle span:nth-child(4) {
  top: 30px;
}

/* footer */
footer {
  background: #99cc66;
  padding-top: 12px;
  padding-bottom: 10px;
}

@media screen and (min-width: 768px) {
  footer {
    padding-top: 20px;
    padding-bottom: 14px;
  }
}

footer div.wrap {
  margin: 0 auto;
  font-size: 12px;
  color: #ffffff;
  max-width: 240px;
}

@media screen and (min-width: 768px) {
  footer div.wrap {
    max-width: 960px;
    text-align: left;
  }
}

footer div.wrap div.contactinfo p a {
  color: inherit;
  display: inline;
}

footer div.wrap small {
  font-size: 10px;
}

@media screen and (min-width: 768px) {
  footer div.box_flex {
    width: 356px;
  }
}

footer div.box_flex div {
  padding-bottom: 10px;
}

footer div.box_flex div img {
  width: 100px;
}

div.top_vi_content.box_flex {
  position: fixed;
  z-index: -1;
  padding-top: 62px;
}

@media screen and (min-width: 768px) {
  div.top_vi_content.box_flex {
    padding-top: 80px;
  }
}

div.top_vi_content.box_flex div.top_textarea {
  background: #99cc66;
  padding-bottom: 31px;
  padding-top: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.top_vi_content.box_flex div.top_textarea img {
  width: 38%;
}

@media screen and (min-width: 768px) {
  div.top_vi_content.box_flex div.top_textarea img {
    width: 66%;
  }
}

@media screen and (min-width: 768px) {
  div.top_vi_content.box_flex div.top_textarea {
    width: 32%;
  }
}

@media screen and (min-width: 768px) {
  div.top_vi_content.box_flex div.top_imgarea {
    width: 68%;
  }
}

div.top_vi_content.box_flex div.top_imgarea ul {
  display: flex;
  flex-wrap: wrap;
}

div.top_vi_content.box_flex div.top_imgarea ul li {
  width: calc(100% / 3);
}

/* .main_container */
div.main_container #change_height {
  padding-top: 62px;
}

@media screen and (min-width: 768px) {
  div.main_container #change_height {
    padding-top: 80px;
  }
}

div.main_container section {
  padding: 40px 0;
}

@media screen and (min-width: 768px) {
  div.main_container section {
    padding: 85px 0 100px;
  }
}

/* topのworks */
section.top_works {
  background: #f3f4ea;
}

@media screen and (min-width: 768px) {
  section.top_works div.wrap h2.top_title {
    margin-bottom: 40px;
  }
}

section.top_works div.wrap h2.top_title img {
  width: 116px;
}

div.works_textarea {
  text-align: center;
  margin-bottom: 22px;
}

@media screen and (min-width: 768px) {
  div.works_textarea {
    margin-bottom: 60px;
  }
  div.works_textarea p {
    font-size: 20px;
  }
}

div.works_linkarea.box_flex {
  max-width: 320px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  div.works_linkarea.box_flex {
    max-width: inherit;
    margin-left: -7px;
    flex-wrap: nowrap;
  }
}

div.works_linkarea.box_flex a {
  margin-bottom: 6px;
}

@media screen and (min-width: 768px) {
  div.works_linkarea.box_flex a {
    margin-bottom: 0;
    margin-left: 7px;
  }
  div.works_linkarea.box_flex a:hover {
    padding: 0;
    margin-left: 5px;
  }
}

div.works_linkarea.box_flex a:nth-last-child(1) {
  margin-bottom: 0;
}

/* topのmessage */
section.top_message {
  background: #fff;
}

section.top_message div.wrap h2.top_title img {
  width: 112px;
}

@media screen and (min-width: 768px) {
  div.box_position {
    position: relative;
  }
}

@media screen and (min-width: 768px) {
  div.box_position div.message_imgarea {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 260px;
  }
}

div.box_position div.message_textarea {
  margin-bottom: 22px;
}

@media screen and (min-width: 768px) {
  div.box_position div.message_textarea {
    margin-bottom: 24px;
    width: calc(100% - 260px);
    letter-spacing: -1px;
  }
  div.box_position div.message_textarea p {
    line-height: 1.7;
  }
  div.box_position div.message_textarea p + p {
    margin-top: 1em;
  }
}

div.box_position div.message_textarea p {
  line-height: 1.9;
}

div.box_position div.message_textarea .zayuu {
  font-size: 20px;
}

/* topのblog */
section.top_blog {
  background: #f3f4ea;
}

section.top_blog div.wrap h2.top_title img {
  width: 62px;
}

div.blog_listarea.box_flex {
  margin-bottom: 22px;
  justify-content: flex-start;
}

@media screen and (min-width: 768px) {
  div.blog_listarea.box_flex {
    margin-bottom: 40px;
    flex-wrap: nowrap;
    margin-left: -19px;
  }
}

div.blog_listarea.box_flex .blog_list {
  background: #fff;
  display: flex;
  padding: 8px 10px 7px 0;
  margin-bottom: 15px;
  position: relative;
}

@media screen and (min-width: 768px) {
  div.blog_listarea.box_flex .blog_list {
    width: calc(33.3% - 30px);
    padding: 0;
    display: block;
    margin-left: 30px;
  }
}

div.blog_listarea.box_flex .blog_list div.img_center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
  padding-left: 7px;
  padding-right: 8px;
}

@media screen and (min-width: 768px) {
  div.blog_listarea.box_flex .blog_list div.img_center {
    width: 100%;
    padding: 0;
    height: 200px;
    overflow: hidden;
  }
}

div.blog_listarea.box_flex .blog_list div.img_center img {
  height: auto;
}

div.blog_content {
  width: 65%;
}

@media screen and (min-width: 768px) {
  div.blog_content {
    width: 100%;
    position: relative;
    padding: 60px 25px 50px;
  }
}

div.blog_content div.bloglist_textarea p.blog_time {
  font-size: 10px;
}

@media screen and (min-width: 768px) {
  div.blog_content div.bloglist_textarea p.blog_time {
    font-size: inherit;
  }
}

div.blog_content div.bloglist_textarea p.h_1em.blog_title {
  font-weight: bold;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  div.blog_content div.bloglist_textarea p.h_1em.blog_title {
    font-size: 16px;
  }
}

div.blog_content div.bloglist_textarea p.h_3em.blog_excerpt {
  font-size: 13px;
}

@media screen and (min-width: 768px) {
  div.blog_content div.bloglist_textarea p.h_3em.blog_excerpt {
    font-size: inherit;
    height: 92px;
    overflow: hidden;
  }
}

div.blog_content div.bloglist_textarea p.h_3em.blog_excerpt .continue {
  display: none;
}

@media screen and (min-width: 768px) {
  div.blog_content div.bloglist_textarea {
    padding-bottom: 30px;
    border-bottom: 0.5px solid #e0e1dc;
  }
}

div.blog_content > p {
  position: absolute;
  bottom: 7px;
  right: 10px;
  text-align: right;
}

@media screen and (min-width: 768px) {
  div.blog_content > p {
    padding-top: 7px;
    bottom: 20px;
    right: 25px;
  }
}

div.blog_content > p img {
  width: 84px;
}

@media screen and (min-width: 768px) {
  div.blog_content > p img {
    width: 90px;
  }
}

div.blog_content .post-categories li {
  top: 3px;
  left: -6px;
  position: absolute;
  font-size: 10px;
  color: #fff;
  padding: 0 10px;
}

@media screen and (min-width: 768px) {
  div.blog_content .post-categories li {
    top: 20px;
    left: -11px;
    font-size: inherit;
    padding: 2px 36px;
  }
}

div.blog_content .post-categories li::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 5px 0;
}

@media screen and (min-width: 768px) {
  div.blog_content .post-categories li::after {
    border-width: 0 11px 9px 0;
    bottom: -9px;
  }
}

div.blog_content .post-categories li.cat-1::after {
  border-color: transparent #343504 transparent transparent;
}

div.blog_content .post-categories li.cat-2::after {
  border-color: transparent #336633 transparent transparent;
}

div.blog_content .post-categories li.cat-3::after {
  border-color: transparent #cc6600 transparent transparent;
}

div.blog_content .post-categories li.cat-4::after {
  border-color: transparent #c03740 transparent transparent;
}

div.blog_content .post-categories li.cat-5::after {
  border-color: transparent #535FA3 transparent transparent;
}

.low_dir.aboutus .ld_title img {
  max-width: 400px;
}

@media screen and (min-width: 768px) {
  .low_dir.aboutus .ld_title img {
    width: 467px;
  }
}

@media screen and (max-width: 530px) {
  .low_dir.aboutus .ld_title span.sub_title {
    display: block;
    margin-left: 0;
  }
}

@media screen and (min-width: 768px) {
  div.aboutus_content {
    margin-bottom: 60px;
  }
}

div.aboutus_content dl {
  border-bottom: 0.5px solid #cccccc;
  display: flex;
}

div.aboutus_content dl:nth-child(1) {
  border-top: 0.5px solid #cccccc;
}

div.aboutus_content dl:nth-child(2n+1) {
  background: #f3f4ea;
}

@media screen and (min-width: 768px) {
  div.aboutus_content dl {
    height: 57px;
  }
}

div.aboutus_content dl dt {
  display: flex;
  align-items: center;
  padding-left: 4%;
  width: 19%;
}

div.aboutus_content dl dd {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .map_area {
    cursor: pointer;
    max-width: 788px;
    margin: 0 auto;
  }
}

.low_dir.blog .ld_title img {
  max-width: 91px;
}

@media screen and (min-width: 768px) {
  .low_dir.blog .ld_title img {
    width: 106px;
  }
}

.low_dir.blog .ld_title .archive-title {
  margin-left: 30px;
}

@media screen and (min-width: 768px) {
  div.container.box_flex div.bloglist_contents {
    width: calc(100% - 266px);
    max-width: 693px;
  }
}

div.bloglist_content img {
  width: inherit;
  height: inherit;
}

div.bloglist_content + .bloglist_content {
  margin-top: 50px;
}

@media screen and (min-width: 768px) {
  div.bloglist_content + .bloglist_content {
    margin-top: 80px;
  }
}

div.bloglist_content .blog_contenttitle {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 2px solid #99cc66;
}

@media screen and (min-width: 768px) {
  div.bloglist_content .blog_contenttitle {
    padding-bottom: 10px;
    margin-bottom: 56px;
  }
}

div.bloglist_content .blog_contenttitle h3 {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 10px;
}

@media screen and (min-width: 768px) {
  div.bloglist_content .blog_contenttitle h3 {
    font-size: 20px;
    padding-bottom: 5px;
  }
}

@media screen and (min-width: 768px) {
  div.bloglist_content .blog_contenttitle p {
    display: inline;
    font-size: 15px;
    margin-right: 18px;
  }
}

div.bloglist_content .blog_contenttitle .post-categories {
  display: inline-block;
}

div.bloglist_content .blog_contenttitle .post-categories li {
  padding-left: 34px;
  padding-right: 34px;
  display: inline-block;
}

@media screen and (min-width: 768px) {
  div.blog_subcontents {
    width: 200px;
  }
}

div.blog_subcontents p.blog_subtitle {
  border-bottom: 2px solid #99cc66;
}

@media screen and (min-width: 768px) {
  div.blog_subcontents p.blog_subtitle {
    padding-left: 12px;
    padding-bottom: 19px;
  }
}

@media screen and (min-width: 768px) {
  div.blog_subcontents ul li {
    padding-left: 12px;
    border-bottom: 1px solid #dddddd;
  }
}

div.blog_subcontent1 p.blog_subtitle img {
  width: 124px;
}

div.blog_subcontent1 li {
  position: relative;
  padding-top: 37px;
}

@media screen and (min-width: 768px) {
  div.blog_subcontent1 li {
    padding-bottom: 13px;
  }
}

div.blog_subcontent1 li .post-date {
  font-size: 8px;
  position: absolute;
  top: 15px;
  left: 0;
}

@media screen and (min-width: 768px) {
  div.blog_subcontent1 li .post-date {
    left: 12px;
  }
}

@media screen and (min-width: 768px) {
  div.blog_subcontent2 {
    margin-top: 60px;
  }
}

div.blog_subcontent2 p.blog_subtitle img {
  width: 95px;
}

@media screen and (min-width: 768px) {
  div.blog_subcontent2 li {
    padding-top: 18px;
    padding-bottom: 16px;
  }
}

/* wordpress post-navigation */
.post-navigation {
  margin-top: 3em;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

.nav-links {
  border-top: 1px solid #ccc;
  padding: .5em 0;
}

.nav-links:after {
  content: "";
  display: block;
  clear: both;
}

.nav-previous {
  float: left;
}

.nav-next {
  float: right;
  text-align: right;
}

.nav-previous,
.nav-next {
  width: 40%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* wordpress pagination */
.pagination h2.screen-reader-text {
  display: none;
}

.pagination {
  position: relative !important;
  overflow: hidden;
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
  clear: both;
  background-color: transparent;
}

.pagination a {
  padding: 1px 8px;
  display: inline-block;
  border: solid 1px #cccccc;
  color: #666666;
  background-color: #ffffff;
  font-size: 1em;
  text-decoration: none;
}

.pagination a:hover {
  background-color: #e8e8e8;
}

.pagination span {
  padding: 1px 8px;
  display: inline-block;
}

.pagination span.current {
  color: #ffffff;
  font-size: 1em;
  background-color: #99cc66;
  border: 1px solid #99cc66;
}

.pagination h2.screen-reader-text {
  display: none;
}

/* 768px以上 */
@media (min-width: 768px) {
  .pagination a {
    padding: 1px 10px;
    display: inline-block;
    border: solid 1px #cccccc;
    color: #666666;
    background-color: #ffffff;
    font-size: 1.19em;
    text-decoration: none;
  }
  .pagination span {
    padding: 1px 10px;
    display: inline-block;
  }
  .pagination span.current {
    color: #ffffff;
    font-size: 1.19em;
    background-color: #99cc66;
    border: 1px solid #99cc66;
  }
}

.continue {
  display: inline-block;
  margin-left: 1em;
}

.side_ttl {
  display: none;
}

.works .ld_title {
  text-align: center;
}

.works .ld_title img {
  width: 171px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 768px) {
  .works .ld_title img {
    width: 200px;
  }
}

@media screen and (min-width: 768px) {
  .works .ld_title2 {
    text-align: left;
    margin: 0 0 50px;
  }
}

.works .ld_title2 img {
  width: 240px;
}

@media screen and (min-width: 768px) {
  .works .ld_title2 img {
    width: 378px;
  }
}

@media screen and (max-width: 402px) {
  .works .ld_title2 span.sub_title {
    margin-left: 0;
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .works .ld_title3 {
    margin: 0 0 50px;
    text-align: left;
  }
}

@media screen and (min-width: 768px) {
  .works .ld_title3 img {
    width: 267px;
  }
}

.works .works_text2 {
  margin-bottom: 37px;
}

@media screen and (min-width: 768px) {
  .works .works_text2 {
    margin-bottom: 75px;
  }
}

div.works_topvi {
  text-align: center;
  margin-bottom: 80px;
}

@media screen and (min-width: 768px) {
  div.works_topvi {
    margin-bottom: 135px;
  }
}

div.works_topvi .works_text {
  margin: 0 auto 30px;
  width: 92%;
  font-size: 16px;
  line-height: 2.3;
  text-align: left;
}

@media screen and (min-width: 768px) {
  div.works_topvi .works_text {
    width: inherit;
    text-align: center;
    font-size: 2.5vw;
    margin-bottom: 135px;
  }
}

@media screen and (min-width: 1180px) {
  div.works_topvi .works_text {
    font-size: 30px;
  }
}

@media screen and (min-width: 768px) {
  div.works_topvi img {
    /*
            &.works_text {
                max-width: 788px;
                margin-bottom: 135px;
            }
            */
  }
  div.works_topvi img.works_catch {
    max-width: 499px;
    margin-bottom: 84px;
  }
}

@media screen and (min-width: 768px) {
  div.works_topvi div.worksimg_contents.box_flex {
    margin-bottom: 35px;
  }
}

.works_text1 {
  text-align: left;
  font-size: 16px;
  margin: 0 auto 80px;
}

.works_text1 ul {
  width: 100%;
}

.works_text1 li {
  width: 100%;
  margin-top: 0.5em;
}

.works_text1 li::before {
  content: '●';
  display: inline-block;
  margin-right: 1em;
}

@media screen and (min-width: 768px) {
  .works_text1 {
    line-height: 1.75;
    max-width: 100%;
  }
}

div.works_content div.rinen_content:nth-last-child(1) {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  div.works_content div.rinen_content {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 768px) {
  div.works_content div.rinen_content h3 {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 768px) {
  div.works_content div.rinen_content1 h3 img {
    width: 298px;
  }
}

@media screen and (min-width: 768px) {
  div.works_content div.rinen_content2 h3 img {
    width: 417px;
  }
}

@media screen and (min-width: 768px) {
  div.works_content div.rinen_content3 h3 img {
    width: 478px;
  }
}

/* link */
.low_dir.link .ld_title img {
  width: 92px;
}

@media screen and (min-width: 768px) {
  .low_dir.link .ld_title img {
    width: 103px;
  }
}

.low_dir.link .link_list {
  border-bottom: 1px solid #c3c3c3;
  padding: 1em;
}

.low_dir.link .link_list:nth-of-type(1) {
  border-top: 1px solid #c3c3c3;
}

.low_dir.link .link_list .link_url {
  padding-top: 0.6em;
}

@media screen and (min-width: 768px) {
  .low_dir.link .link_list {
    padding: 20px 0;
    display: flex;
  }
  .low_dir.link .link_list .link_name {
    width: 31%;
    padding: 0 0 0 40px;
  }
  .low_dir.link .link_list .link_url {
    padding: 0 40px 0 40px;
  }
}

.low_dir.contact .ld_title img {
  width: 171px;
}

@media screen and (min-width: 768px) {
  .low_dir.contact .ld_title img {
    width: 200px;
  }
}

div.container .contact_content .form_list + .form_list {
  margin-top: 16px;
}

div.container .contact_content .form_list:nth-last-of-type(1) {
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  div.container .contact_content .form_list:nth-last-of-type(1) {
    margin-bottom: 55px;
  }
}

div.container .contact_content .form_list dt {
  padding-top: 9px;
  line-height: 1.4;
  font-size: 14px;
}

@media screen and (min-width: 768px) {
  div.container .contact_content .form_list dt {
    text-align: right;
  }
}

div.container .contact_content .form_list dd {
  margin-top: 0.5em;
  padding-top: 9px;
  line-height: 1.4;
}

@media screen and (min-width: 768px) {
  div.container .contact_content .form_list dd {
    margin-top: 0;
    width: calc(100% - 183px);
  }
}

div.container .contact_content .form_list .required {
  color: #ff0000;
  margin-right: 0.5em;
}

@media screen and (min-width: 768px) {
  div.container .contact_content .form_list dt {
    width: 183px;
    padding-right: 18px;
  }
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  height: 100%;
  box-sizing: border-box;
  border: 0.5px solid #333333;
  width: 100%;
  font-size: 16px;
  padding: 0.5em;
}

@media screen and (min-width: 768px) {
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    padding: 1em;
  }
}

textarea {
  height: 147px;
}

.btn_confirm {
  display: block;
  text-align: center;
  color: #fff;
  background: #006633;
  max-width: 390px;
  width: 100%;
  margin: 0 auto;
  line-height: 50px;
  font-size: 15px;
}

@media screen and (min-width: 768px) {
  .btn_confirm {
    font-size: 18px;
    line-height: 70px;
  }
}

.btn_confirm::after {
  content: '\003e';
  display: inline-block;
  margin-left: 2em;
}

.bl_modalBlock {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 100;
}

.js_openModal .bl_modalBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.bl_modalBlock_wrapper {
  width: 90%;
  max-width: 480px;
  height: auto;
  position: relative;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
}

.bl_modalBlock_wrapper:before {
  content: "";
  display: block;
  padding-top: 100%;
}

@media screen and (min-width: 768px) {
  .bl_modalBlock_wrapper {
    width: 80vh;
    max-width: inherit;
  }
  .bl_modalBlock_wrapper.map_area_modal {
    width: 70%;
  }
}

.bl_modalBlock_closeButton {
  position: absolute;
  right: 0;
  top: -10px;
  width: 20px;
  height: 20px;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  cursor: pointer;
}

.bl_modalBlock_closeButton:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 149%;
  height: 2px;
  background-color: #ccc;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bl_modalBlock_closeButton:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 149%;
  height: 2px;
  background-color: #ccc;
  -webkit-transform-origin: top right;
  -ms-transform-origin: top right;
  transform-origin: top right;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media screen and (min-width: 768px) {
  .bl_modalBlock_closeButton.map_area_modal {
    top: 16%;
  }
}

/*スクロール禁止*/
.js_fixed {
  position: fixed;
  width: 100%;
  height: 100%;
}
