// ----------------------------------
// サイトの基本設定
// ------------------------------------

//幅関係の設定
$width-base: 960px; //全体の幅



//フォントの設定
$font-base: "ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
"ＭＳ Ｐゴシック",
sans-serif;
$font-serif: "ヒラギノ明朝 ProN W3",
"Hiragino Mincho ProN",
"HG明朝E",
"ＭＳ Ｐ明朝",
"ＭＳ 明朝",
serif;


//色関係の設定
//フォントカラー
$color-font: #333333;
//サイトカラー
$color-bg: #f3f4ea;
$color-main: #99cc66; //94bb62
$color-btn: #006633;
$color-spnav: #00a746;
//カテゴリーの色分け
$color-cat1: #4A4A4A;
$color-cat2: #8bb543;
$color-cat3: #d7972d;
$color-cat4: #C05E40;
$color-cat5: #539AA3;

//font-size
$fontsize-base: 14px;

//borderの設定
$border-green: 2px solid $color-main;
$border-gray: 0.5px solid #cccccc;

//行間
$line_height: 1.7;

//formの角丸
//$radius-form: 2.5px;

//sp-headerのheight
$sph_height: 62px;
//pc-headerのheight
$pch_height: 80px;


//IMG_PATH
$IMG_PATH: '../images/';

//ブレイクポイント
$breakpoints: (tb:"screen and (max-width:767px)",
pc:"screen and (min-width:768px)",
);

@mixin media($breakpoint) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}

//疑似クラス用
@mixin gizi {
    content: '';
    display: block;
}

//文字の均等配置
@mixin kintou {
    -moz-text-align-lastc: justify;
    text-align-last: justify;
    text-justify: inter-ideograph;
}

//真ん中中央
@mixin mannaka {
    display: flex;
    justify-content: center;
    align-items: center;
}

//clearfix
@mixin clearfix {
    content: '';
    display: block;
    clear: both;
}