div.top_vi_content.box_flex {
    position: fixed;
    z-index: -1;
    padding-top: $sph_height;

    @include media(pc) {
        padding-top: $pch_height;
    }

    div.top_textarea {
        background: $color-main;
        padding-bottom: 31px;
        padding-top: 39px;
        @include mannaka;

        img {
            width: 38%;

            @include media(pc) {
                width: 66%;
            }
        }

        @include media(pc) {
            width: 32%;
        }
    }

    div.top_imgarea {
        @include media(pc) {
            width: 68%;
        }

        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                width: calc(100% / 3);
            }
        }
    }
}

/* .main_container */
div.main_container {
    #change_height {
        padding-top: $sph_height;

        @include media(pc) {
            padding-top: $pch_height;
        }
    }

    section {
        padding: 40px 0;

        @include media(pc) {
            padding: 85px 0 100px;
        }
    }
}